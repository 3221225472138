var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        _vm._b(
          {
            attrs: {
              flat: "",
              color: "primary lighten-4 mr-1 mb-1",
              ripple: false,
            },
          },
          "v-card",
          {
            href: _vm.isUpload ? undefined : _vm.imageLink,
            target: _vm.isUpload ? undefined : "_blank",
          },
          false
        ),
        [
          _c(
            "div",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    "no-gutters": "",
                    align: _vm.fileType !== "image" ? "center" : "",
                  },
                },
                [
                  _c(
                    "v-fade-transition",
                    [
                      _vm.uploading
                        ? _c(
                            "v-overlay",
                            { attrs: { absolute: "" } },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", size: "40" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.fileType === "image"
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.imageLink,
                          "max-width": "100px",
                          height: "80px",
                        },
                      })
                    : _c(
                        "v-avatar",
                        {
                          staticClass: "ml-4",
                          attrs: { size: "46", color: "primary lighten-3" },
                        },
                        [
                          _c("v-icon", { attrs: { color: "background" } }, [
                            _vm._v(" " + _vm._s(_vm.fileIcon) + " "),
                          ]),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    {
                      staticClass: "ml-4 my-4",
                      style: `width: calc(100% - ${
                        _vm.fileType !== "image" ? "80px" : "116px"
                      })`,
                    },
                    [
                      _c("v-row", { attrs: { "no-gutters": "" } }, [
                        _c(
                          "div",
                          {
                            style: `width: calc(100% - ${
                              _vm.fileType !== "image" ? "20px" : "24px"
                            })`,
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text--primary font-weight-medium mb-0 text-truncate",
                              },
                              [_vm._v(" " + _vm._s(_vm.name) + " ")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "mb-0",
                                staticStyle: { "white-space": "pre-line" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$filters.formatFileSize(_vm.size)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm.isUpload
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "remove",
                                          _vm.uploadFile
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }