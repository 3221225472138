var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c("v-avatar", { staticClass: "mr-4", attrs: { size: "46" } }, [
        _c("img", { attrs: { src: _vm.avatar } }),
      ]),
      _c(
        "div",
        { staticStyle: { width: "calc(100% - 62px)" } },
        [
          _c("v-textarea", {
            attrs: {
              label: _vm.$t("label-comment"),
              outlined: "",
              rows: "1",
              "hide-details": "",
              "auto-grow": "",
              loading: _vm.loading,
            },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                )
                  return null
                $event.preventDefault()
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onEnter.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "", align: "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: { click: _vm.launchFilePicker },
                          },
                          [_c("v-icon", [_vm._v(" mdi-attachment ")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: "",
                              color: "primary",
                              disabled: _vm.loading,
                            },
                            on: { click: _vm.onEnter },
                          },
                          [_c("v-icon", [_vm._v(" mdi-send ")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.comment.content,
              callback: function ($$v) {
                _vm.$set(_vm.comment, "content", $$v)
              },
              expression: "comment.content",
            },
          }),
          _c("input", {
            ref: "file",
            staticStyle: { display: "none" },
            attrs: {
              id: "file",
              name: "file",
              multiple: "",
              type: "file",
              accept: "*",
            },
            on: { change: _vm.onSelectFile },
          }),
          _c(
            "p",
            {
              staticClass: "text-secondary pa-2",
              staticStyle: { "font-size": "12px", "font-style": "italic" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("text-comment-tip")) + " ")]
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(_vm.comment.files, function (file) {
              return _c("FileItem", {
                key: file.lastModified,
                style: _vm.$vuetify.breakpoint.mobile
                  ? "width: 100%"
                  : "width: 33.33%;",
                attrs: {
                  name: file.name,
                  size: file.size,
                  mimetype: file.type,
                  "is-upload": true,
                  "upload-file": file,
                  uploading: _vm.loading,
                },
                on: { remove: _vm.onRemoveFile },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }