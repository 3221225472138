var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _c("v-avatar", { staticClass: "mr-4", attrs: { size: "46" } }, [
          _c("img", { attrs: { src: _vm.avatar } }),
        ]),
        _c(
          "v-card",
          { attrs: { flat: "", width: "calc(100% - 62px)" } },
          [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "p",
                  {
                    staticClass: "text--primary mb-1",
                    staticStyle: { "font-weight": "600" },
                  },
                  [_vm._v(" " + _vm._s(_vm.name) + " ")]
                ),
                _c("v-spacer"),
                _c("div", { staticClass: "text--secondary" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$filters.formatRelativeTime(_vm.time)) +
                      " "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass: "mb-2",
                staticStyle: { "white-space": "pre-line" },
              },
              [_vm._v(" " + _vm._s(_vm.content) + " ")]
            ),
            _c(
              "v-row",
              { staticClass: "flex-column mb-4", attrs: { "no-gutters": "" } },
              _vm._l(_vm.files, function (file) {
                return _c("FileItem", {
                  key: file.fileName,
                  style: _vm.$vuetify.breakpoint.mobile
                    ? "width: 100%;"
                    : "width: 50%;",
                  attrs: {
                    name: file.displayName,
                    size: file.size,
                    mimetype: file.mimetype,
                    url: file.link,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }