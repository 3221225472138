<template>
  <div>
    <v-card
      flat
      color="primary lighten-4 mr-1 mb-1"
      :ripple="false"
      v-bind="{
        href: isUpload ? undefined : imageLink,
        target: isUpload ? undefined : '_blank',
      }"
    >
      <div>
        <v-row
          no-gutters
          :align="fileType !== 'image' ? 'center' : ''"
        >
          <v-fade-transition>
            <v-overlay
              v-if="uploading"
              absolute
            >
              <v-progress-circular
                indeterminate
                size="40"
              ></v-progress-circular>
            </v-overlay>
          </v-fade-transition>
          <v-img
            v-if="fileType === 'image'"
            :src="imageLink"
            max-width="100px"
            height="80px"
          >
          </v-img>

          <v-avatar
            v-else
            class="ml-4"
            size="46"
            color="primary lighten-3"
          >
            <v-icon color="background">
              {{ fileIcon }}
            </v-icon>
          </v-avatar>

          <div
            class="ml-4 my-4"
            :style="`width: calc(100% - ${
              fileType !== 'image' ? '80px' : '116px'
            })`"
          >
            <v-row no-gutters>
              <div
                :style="`width: calc(100% - ${
                  fileType !== 'image' ? '20px' : '24px'
                })`"
              >
                <p class="text--primary font-weight-medium mb-0 text-truncate">
                  {{ name }}
                </p>
                <p
                  class="mb-0"
                  style="white-space: pre-line"
                >
                  {{ $filters.formatFileSize(size) }}
                </p>
              </div>
              <div v-if="isUpload">
                <v-icon
                  small
                  @click="$emit('remove', uploadFile)"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-row>
          </div>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import mime from 'mime';
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 0,
    },
    mimetype: {
      type: String,
      default: '',
    },
    isUpload: {
      type: Boolean,
      default: false,
    },
    uploadFile: {
      type: File,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
    },
  },
  computed: {
    fileType() {
      if (this.mimetype.includes('image')) {
        return 'image';
      }
      if (this.mimetype.includes('video')) {
        return 'video';
      }
      if (
        [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(this.mimetype)
      ) {
        return 'excel';
      }
      if (
        [
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(this.mimetype)
      ) {
        return 'word';
      }
      if (['application/pdf'].includes(this.mimetype)) {
        return 'pdf';
      }
      if (['text/plain'].includes(this.mimetype)) {
        return 'text';
      }
      return 'other';
    },
    fileIcon() {
      if (this.fileType === 'word') {
        return 'mdi-file-word';
      }
      if (this.fileType === 'excel') {
        return 'mdi-file-excel';
      }
      if (this.fileType === 'pdf') {
        return 'mdi-file-pdf-box';
      }
      return 'mdi-file';
    },
    imageLink() {
      if (this.isUpload) {
        return this.convertToObjectUrl(this.uploadFile);
      } else {
        return this.url;
      }
    },
  },
  methods: {
    convertToObjectUrl(file) {
      const objectURL = URL.createObjectURL(file);
      return objectURL;
    },
  },
};
</script>

<style></style>
