var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-picker" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-wrap",
          class: {
            "card-mobile-overflow": this.$vuetify.breakpoint.mobile,
          },
        },
        [
          _vm._l(_vm.imagesList, function (image) {
            return _c(
              "v-card",
              {
                key: image,
                staticClass: "card pa-1",
                attrs: {
                  flat: "",
                  width: _vm.imageWidth,
                  height: _vm.imageHeight,
                },
              },
              [
                _c("v-img", {
                  attrs: { src: image, height: "100%" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "placeholder",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              {
                                staticClass: "fill-height ma-0",
                                attrs: { align: "center", justify: "center" },
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "grey lighten-5",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c(
                  "v-menu",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "close ma-3",
                                      attrs: {
                                        "x-small": "",
                                        color: "white",
                                        fab: "",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v(" mdi-dots-horizontal "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      [
                        !_vm.disabled
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeImage(image)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", color: "red" } },
                                      [_vm._v("mdi-image-remove")]
                                    ),
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v("Xóa ảnh"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.downloadImage(image)
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { left: "", color: "green" } },
                                  [_vm._v("mdi-download")]
                                ),
                                _c("span", { staticClass: "green--text" }, [
                                  _vm._v("Tải về"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          !_vm.disabled &&
          _vm.imagesList.length !== 0 &&
          !this.$vuetify.breakpoint.mobile
            ? _c(
                "v-card",
                {
                  staticClass: "pa-1",
                  attrs: {
                    flat: "",
                    width: _vm.imageWidth,
                    height: _vm.imageHeight,
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "picker",
                      attrs: {
                        outlined: "",
                        color: "blue-grey",
                        depressed: "",
                        width: "100%",
                        height: "100%",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.launchFilePicker()
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "px-2", attrs: { large: "" } },
                        [_vm._v("mdi-image-plus")]
                      ),
                      !this.$vuetify.breakpoint.mobile
                        ? _c("span", [_vm._v("Thêm ảnh")])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      (!_vm.disabled && _vm.imagesList.length === 0) ||
      (!_vm.disabled && this.$vuetify.breakpoint.mobile)
        ? _c(
            "div",
            {
              staticClass: "pa-1",
              on: {
                click: function ($event) {
                  return _vm.launchFilePicker()
                },
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "picker",
                  attrs: {
                    outlined: "",
                    color: "blue-grey",
                    depressed: "",
                    width: "100%",
                    height: _vm.imagePickerHeight,
                  },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "px-2", attrs: { "x-large": "" } },
                    [_vm._v("mdi-image-plus")]
                  ),
                  _vm._v(" Thêm ảnh "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("vue-compressor", {
        ref: "file",
        attrs: {
          name: _vm.uploadFieldName,
          "is-multiple": true,
          "is-hidden": true,
        },
        on: { "on-compressed": _vm.onFileChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }