<template>
  <div class="image-picker">
    <div
      class="d-flex flex-wrap"
      :class="{
        'card-mobile-overflow': this.$vuetify.breakpoint.mobile,
      }"
    >
      <v-card
        flat
        :width="imageWidth"
        :height="imageHeight"
        class="card pa-1"
        v-for="image of imagesList"
        :key="image"
      >
        <v-img
          :src="image"
          height="100%"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="close ma-3"
              x-small
              color="white"
              fab
            >
              <v-icon> mdi-dots-horizontal </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="!disabled"
              @click="removeImage(image)"
            >
              <v-list-item-title>
                <v-icon
                  left
                  color="red"
                  >mdi-image-remove</v-icon
                >
                <span class="red--text">Xóa ảnh</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="downloadImage(image)">
              <v-list-item-title>
                <v-icon
                  left
                  color="green"
                  >mdi-download</v-icon
                >
                <span class="green--text">Tải về</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card>

      <v-card
        flat
        :width="imageWidth"
        :height="imageHeight"
        class="pa-1"
        v-if="
          !disabled &&
          imagesList.length !== 0 &&
          !this.$vuetify.breakpoint.mobile
        "
      >
        <v-btn
          outlined
          color="blue-grey"
          depressed
          width="100%"
          height="100%"
          class="picker"
          @click="launchFilePicker()"
        >
          <v-icon
            large
            class="px-2"
            >mdi-image-plus</v-icon
          >
          <span v-if="!this.$vuetify.breakpoint.mobile">Thêm ảnh</span>
        </v-btn>
      </v-card>
    </div>

    <div
      class="pa-1"
      v-if="
        (!disabled && imagesList.length === 0) ||
        (!disabled && this.$vuetify.breakpoint.mobile)
      "
      @click="launchFilePicker()"
    >
      <v-btn
        outlined
        color="blue-grey"
        depressed
        width="100%"
        :height="imagePickerHeight"
        class="picker"
      >
        <v-icon
          x-large
          class="px-2"
          >mdi-image-plus</v-icon
        >
        Thêm ảnh
      </v-btn>
    </div>
    <vue-compressor
      ref="file"
      :name="uploadFieldName"
      :is-multiple="true"
      :is-hidden="true"
      @on-compressed="onFileChange"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';

export default {
  name: 'ImagePicker',
  components: {
    VueCompressor: () =>
      import('@/components/library/thirdparty/vue-compressor'),
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imagesList: [],
    uploadFieldName: 'file',
  }),
  computed: {
    imageWidth() {
      if (this.$vuetify.breakpoint.mobile) {
        if (this.imagesList.length === 1) {
          return '100%';
        } else {
          return '50%';
        }
      }
      return '25%';
    },
    imageHeight() {
      if (this.$vuetify.breakpoint.mobile && this.imagesList.length === 1) {
        return '400px';
      }
      return '150px';
    },
    imagePickerHeight() {
      if (this.$vuetify.breakpoint.mobile && this.imagesList.length === 0) {
        return '400px';
      }
      return '100px';
    },
  },
  created() {
    this.imagesList = this.images;
  },
  watch: {
    images: {
      handler(value) {
        this.imagesList = value;
      },
    },
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.$el.click();
    },
    onFileChange(event) {
      this.$emit('new-images', event);
    },
    removeImage(event) {
      this.$emit('remove-image', event);
    },
    downloadImage(event) {
      saveAs(event, event.replace(/^.*[\\/]/, ''));
    },
  },
};
</script>

<style lang="scss" scoped>
.card-mobile-overflow {
  max-height: 400px;
  overflow-y: scroll;
  // overscroll-behavior-y: auto;
}
.card {
  position: relative;
}

.picker {
  border: 3px dashed var(--v-secondary-lighten5);
  cursor: pointer;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
