<template>
  <v-row no-gutters>
    <v-avatar
      class="mr-4"
      size="46"
    >
      <img :src="avatar" />
    </v-avatar>
    <div style="width: calc(100% - 62px)">
      <v-textarea
        v-model="comment.content"
        :label="$t('label-comment')"
        outlined
        rows="1"
        hide-details
        auto-grow
        :loading="loading"
        @keydown.enter.exact.prevent
        @keyup.enter="onEnter"
      >
        <template v-slot:append>
          <v-row
            no-gutters
            align="center"
          >
            <v-btn
              icon
              @click="launchFilePicker"
            >
              <v-icon> mdi-attachment </v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
              :disabled="loading"
              @click="onEnter"
            >
              <v-icon> mdi-send </v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-textarea>
      <input
        id="file"
        ref="file"
        style="display: none"
        name="file"
        multiple
        @change="onSelectFile"
        type="file"
        accept="*"
      />
      <p
        class="text-secondary pa-2"
        style="font-size: 12px; font-style: italic"
      >
        {{ $t('text-comment-tip') }}
      </p>
      <v-row no-gutters>
        <FileItem
          v-for="file in comment.files"
          :key="file.lastModified"
          :style="$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 33.33%;'"
          :name="file.name"
          :size="file.size"
          :mimetype="file.type"
          :is-upload="true"
          :upload-file="file"
          :uploading="loading"
          @remove="onRemoveFile"
        />
      </v-row>
    </div>
  </v-row>
</template>

<script>
import FileItem from './FileItem.vue';
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        content: '',
        files: [],
      }),
    },
    avatar: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { FileItem },
  data: () => ({
    isShiftPressed: false,
  }),
  computed: {
    comment: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit('input', value);
      },
    },
  },
  methods: {
    async onEnter(event) {
      if (event.shiftKey === true && event.key === 'Enter') {
        return;
      }
      if (
        this.comment.content.trim().length === 0 &&
        this.comment.files.length === 0
      ) {
        return;
      }
      this.$emit('send');
    },
    launchFilePicker() {
      this.$refs.file.click();
    },
    async onSelectFile(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const formDataList = [];
        Array.from(files).forEach((file) => {
          this.comment.files.push(file);
          const formData = new FormData();
          formData.append('file', file);
          formDataList.push(formData);
        });
      }
    },
    onRemoveFile(event) {
      this.comment.files = this.comment.files.filter(
        (image) => image !== event
      );
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-input__append-inner {
    margin-top: 10px;
  }
}
</style>
