<template>
  <div>
    <div class="d-flex">
      <v-avatar
        class="mr-4"
        size="46"
      >
        <img :src="avatar" />
      </v-avatar>
      <v-card
        flat
        width="calc(100% - 62px)"
      >
        <v-row no-gutters>
          <p
            class="text--primary mb-1"
            style="font-weight: 600"
          >
            {{ name }}
          </p>
          <v-spacer />
          <div class="text--secondary">
            {{ $filters.formatRelativeTime(time) }}
          </div>
        </v-row>
        <p
          class="mb-2"
          style="white-space: pre-line"
        >
          {{ content }}
        </p>
        <v-row
          no-gutters
          class="flex-column mb-4"
        >
          <FileItem
            v-for="file in files"
            :key="file.fileName"
            :style="$vuetify.breakpoint.mobile ? 'width: 100%;' : 'width: 50%;'"
            :name="file.displayName"
            :size="file.size"
            :mimetype="file.mimetype"
            :url="file.link"
          />
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import FileItem from '@/components/shared/local/base/FileItem.vue';
export default {
  components: { FileItem },
  props: {
    avatar: {
      type: String,
    },
    name: {
      type: String,
    },
    time: {
      type: String,
    },
    content: {
      type: String,
    },
    files: {
      type: Array,
    },
  },
};
</script>

<style></style>
